import { useNavigate } from "react-router-dom";
import Item from "./Item";
import "../../assets/css/Game.css";
export default function Success({ prize }: { prize: any }) {
  const navigate = useNavigate();
  const handleNavigateSucess = () => {
    navigate(`/gifts`);
    localStorage.removeItem("PRIZE_LIST");
  };
  return (
    <div className="bg-success">
      <div className="animation-success min-h-screen">
        <nav></nav>
        <div className="relative top-0">
          <div className=" flex flex-col items-center justify-center text-center text-[24px]">
            <h1 className="mt-8 text-[25px] font-bold-mon leading-9 ani-title uppercase px-10 text-white">
              chúc mừng bạn
              <br /> đã trúng
            </h1>
          </div>
          <div className="containerSuccess__background absolute top-28 h-[100%] z-10 w-full px-10">
            <div className="containerSuccess__background-list z-20 ">
              <div className="mt-5 ">
                {prize?.map((res: any, index: number) => (
                  <div key={index}>
                    <Item res={res} />
                  </div>
                ))}

                <div className="flex justify-center mb-36">
                  <button
                    className="w-40 py-3 bg-[#CC0403] rounded-lg text-[#F8C158] font-bebas-mon text-[24px]"
                    onClick={handleNavigateSucess}
                  >
                    Đồng ý
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <FooterLogo /> */}
      </div>
    </div>
  );
}
