import { ErrorMessage } from "@hookform/error-message";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import ICON_PHONE from "../../assets/image/Icon/icon-iphone.png";
import { AppDispatch } from "../../Redux/stores/store";
import "../../assets/css/Login.css";
import { LoginServices } from "../../services/apiService/LoginService";
import { useNavigate } from "react-router-dom";
import { userLocal } from "../../services/localService/localService";
import TcLogin from "../../components/Popup/TcLogin";
import { GameServices } from "../../services/apiService/GameService";
import Logo from "../../components/NavBar/Logo";

const style = {
  color: "#F8C158",
  fontHeight: "20px",
  fontWeight: "600",
};
const styleButton = {
  color: "#333",
  fontWeight: "600",
  lineHeight: "20px",
};
export default function Login() {
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm({
    criteriaMode: "all",
  });
  let { token } = userLocal.get();

  const navigation = useNavigate();
  const [isPopup, setIsOpenPopup] = useState<boolean>(false);
  let dispatch = useDispatch<AppDispatch>();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [checkAgree, setCheckAgree] = useState<boolean>(false);
  const [statusDisable, setStatusDisable] = useState<boolean>(false);
  const [isError, setIsError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [tc, setTC] = useState<any>();
  const getDAta = (campaign_id: number, token: any) => {
    GameServices.GameDetailsApi(campaign_id, token)
      .then((res: any) => {
        console.log(res);
        localStorage.setItem("DATA_LEADER_BOARD", JSON.stringify(res));
        setTC(res);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    getDAta(1, token);
  }, []);
  const onSubmit = (data: any) => {
    setCheckAgree(true);
    setStatusDisable(true);
    console.log(data);
    LoginServices.LoginApi(data)
      .then((res: any) => {
        console.log(res);
        localStorage.setItem("PHONE", data.phone);
        localStorage.setItem("NAME", data.name);
        navigation("/validate-otp");
        userLocal.set(res);
      })
      .catch((err) => {
        setIsError(err);
        console.log(err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  const handleClickCheck = () => {
    setCheckAgree(!checkAgree);
  };
  const handleOpenTC = () => {
    setIsOpen(true);
    setCheckAgree(true);
  };
  return (
    <div>
      <div className="container bg_default text-[#fff] w-screen min-w-full h-full min-h-screen px-[25px] flex flex-col box-border">
        <div className="icon-back mt-[7%] py-[36px] h-8 flex items-center opacity-100 max-w-full w-full z-50"></div>
        <header className="w-full font-bebas-mon h-9 leading-9 text-[34px] uppercase text-[#CC0403]">
          Đăng nhập Thông tin
        </header>
        <div className="p-[16px_0_8px_0] text-[#333333] text-base font-regular-mon w-[87%]">
          Nhập số điện thoại của bạn để nhận phần thưởng
        </div>
        <div className="mt-5">
          <form className="form" onSubmit={handleSubmit(onSubmit)}>
            <div className="form__login">
              <div className="flex flex-nowrap h-[70px] rounded-[14px] mt-0 bg-[#fff] relative z-0 border-[#F3AB1F] border-[1px]">
                <input
                  className="form__phone input-hidden text-[15px] font-regular-mon pl-4 h-full z-10 w-full text-black rounded-r-xl rounded-l-xl"
                  placeholder="Nhập tên của bạn "
                  {...register("name", {
                    required: "Không được để trống",
                    pattern: {
                      value: /^[\D*]{1,}$/,
                      message: "Vui lòng chỉ nhập kí tự",
                    },
                  })}
                />
              </div>
              <div className="font-normal z-0 font-[Montserrat-Regular] mb-7 text-[red] text-[13px] text-center">
                <ErrorMessage
                  errors={errors}
                  name="name"
                  render={({ messages }) => {
                    console.log("messages", messages);
                    return messages
                      ? Object.entries(messages).map(([type, message]) => (
                          <p
                            key={type}
                            className="bg-[#EEE6E7] pt-2.5 -mt-2.5 z-0 rounded-bl-xl rounded-br-xl border-[#F63440] border-[1px]"
                          >
                            {message}
                          </p>
                        ))
                      : null;
                  }}
                />
              </div>
              <div className="flex h-[70px] rounded-[14px] mt-0 bg-[#fff] relative z-0 border-[#F3AB1F] border-[1px]">
                <div className="icon z-20 flex justify-center items-center text-[13px] ">
                  <img src={ICON_PHONE} />
                </div>
                <input
                  className="form__phone input-hidden text-[15px] flex-[6] w-52 z-30 text-black font-regular-mon rounded-r-xl"
                  placeholder="Nhập số điện thoại "
                  type="tel"
                  {...register("phone", {
                    required: "Không được để trống",
                    pattern: {
                      value: /(84|0[3|5|7|8|9])+([0-9]{8})\b/,
                      message: "Vui lòng nhập số",
                    },
                  })}
                />
              </div>
              <div className="z-0 font-regular-mon text-[red] text-[13px] text-center">
                <ErrorMessage
                  errors={errors}
                  name="phone"
                  render={({ messages }) => {
                    console.log("messages", messages);
                    return messages
                      ? Object.entries(messages).map(([type, message]) => (
                          <p
                            key={type}
                            className="bg-[#EEE6E7] pt-2.5 -mt-2.5 z-0 rounded-bl-xl rounded-br-xl border-[#F63440] border-[1px]"
                          >
                            {message}
                          </p>
                        ))
                      : null;
                  }}
                />
              </div>
              <div className="m-[46px_0_28px_0] flex font-light-mon text-black">
                <div className="flex items-center mb-4">
                  <input
                    id="default-checkbox"
                    type="checkbox"
                    onClick={() => handleClickCheck()}
                    checked={checkAgree}
                    defaultChecked={checkAgree}
                    className="checkbox-confirm w-5 h-5"
                  />
                </div>
                <label
                  htmlFor="check"
                  className="mr-[10px]  font-normal text-[13px] leading-[20px]"
                >
                  {" "}
                </label>
                <label
                  htmlFor="check"
                  className="mr-[10px] font-regular-mon font-normal text-[13px] leading-[20px]"
                >
                  Tôi đồng ý với các{" "}
                  <span className="text-yellow-400" onClick={handleOpenTC}>
                    Điều khoản & điều kiện
                  </span>{" "}
                  của chương trình
                </label>
              </div>
              <>
                {checkAgree === true ? (
                  <>
                    <div className="flex justify-center mt-5 box-border text-[#F8C158]">
                      {!statusDisable ? (
                        <input
                          style={style}
                          type="submit"
                          className="color-button-enable button-login font-bebas-mon h-[56px]"
                          value="Tiếp tục"
                        />
                      ) : (
                        <div className="color-button-enable button-login font-bebas-mon w-[148px] flex justify-center h-[56px]">
                          <div role="status">
                            <svg
                              aria-hidden="true"
                              className="w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
                              viewBox="0 0 100 101"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                fill="currentColor"
                              />
                              <path
                                d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                fill="currentFill"
                              />
                            </svg>
                            <span className="sr-only">Loading...</span>
                          </div>
                        </div>
                      )}
                    </div>
                  </>
                ) : (
                  <div className="flex justify-center mt-5 box-border text-[#333]">
                    <input
                      style={styleButton}
                      type="button"
                      className="color-button-disable button-login font-bebas-mon h-[56px]"
                      value="Tiếp tục"
                    />
                  </div>
                )}
              </>
            </div>
          </form>
        </div>
      </div>
      {isOpen ? (
        <TcLogin
          popup={isOpen}
          message={tc?.content_details}
          setCheckAgree={setCheckAgree}
          setIsOpen={setIsOpen}
        />
      ) : null}
      <Logo />
    </div>
  );
}
