import { useEffect, useState } from "react";
import TITLE_BG from "../../assets/image/CountDown/waiting.png";
import StartLoading from "./StartLoading";
import PHONE from "../../assets/image/CountDown/phone.png";
import ContactPhoneZalo from "../ContactPhoneZalo/ContactPhoneZalo";

export default function Waiting() {
  const [percent, setPercent] = useState(0);
  const [count, setCount] = useState(0);
  const { hotline } = JSON.parse(
    localStorage.getItem("HOME_DATA_LOCAL") || "{}"
  );
  useEffect(() => {
    const interval = setInterval(() => {
      setPercent((prevCount) => {
        if (prevCount >= 100) {
          setCount(count + 1);
          clearInterval(interval);
          return 100;
        }
        return prevCount + 1;
      });
    }, 80);
    return () => clearInterval(interval);
  }, []);

  return (
    <div>
      <div className="container bg_default text-[#fff] w-screen min-w-full h-full min-h-screen px-[25px] flex flex-col box-border">
        <div className="text-[#CC0403] font-bebas-mon text-[45px] text-center mt-5">
          HỆ THỐNG ĐANG XỬ LÝ
        </div>
        <div className="flex justify-center mt-3">
          <img src={TITLE_BG} />
        </div>
        <div className="text-black text-center font-semibold-mon mt-8">
          Vui lòng đợi trong giây lát
        </div>
        <div className="mt-2">
          <StartLoading width={100} title="" gift="" />
          <div className="timer">
            <span className="countdown-text font-bebas-mon mt-5 text-black text-[36px] font-bold-so flex justify-center">
              {" "}
              <div className="text-center flex justify-center">{percent}%</div>
            </span>
          </div>
        </div>
        <div className="relative left-1/2 -translate-x-1/2 flex justify-center items-center w-full mt-20">
          <div>
            <img src={PHONE} />
          </div>
          <div className="text-[#2D4E68] font-regular-mon ml-2">
            Liên hệ Hotline{" "}
            <span className="font-semibold-mon text-[#CC0403]">
              <a href={`tel:${hotline}`}>{hotline}</a>
            </span>
          </div>
        </div>
      </div>
      <ContactPhoneZalo />
    </div>
  );
}
