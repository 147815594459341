export default function ParticipateHisItem({
  lstProduct,
}: {
  lstProduct: any;
}) {
  console.log(lstProduct);

  return (
    <>
      <div className="grid grid-cols-12">
        <div className="col-span-3">
          <img
            src={lstProduct?.receipt_image_url}
            className=" w-11/12 rounded-tl-xl rounded-bl-xl"
          />
        </div>

        <div className="containerNotify__background-itemChild pl-1 pr-2 box-border py-2 col-span-9">
          <div className="grid grid-cols-9 text-[13px] font-semibold-mon">
            <div className="col-span-3">Tên siêu thị</div>
            <div className="font-regular-italic col-span-6 text-reponsive text-right">
              {lstProduct?.store_name}
            </div>
          </div>
          <div className="content-notify grid grid-cols-9 font-semibold-mon text-[13px]">
            <div className="col-span-4">Số hóa đơn</div>
            <div className="font-light-mon text-right col-span-5">
              {lstProduct?.receipt_no}
            </div>
          </div>
          <div className="text-[13px] font-semibold-mon grid grid-cols-9">
            <div className="col-span-4">Trạng thái</div>
            <div
              className="text-[#D4961D] text-right col-span-5 font-italic-mon"
              style={{
                color:
                  lstProduct?.state === "in_progress"
                    ? "#D4961D"
                    : lstProduct?.state === "done"
                    ? "#0AAA52"
                    : "#F30D0C",
              }}
            >
              {lstProduct?.status}
            </div>
          </div>
          <div className="text-[13px] grid grid-cols-2 font-semibold-mon">
            <div className="col-span-1">Ngày dự thưởng</div>
            <div className="font-light-mon col-span-1 text-right">
              {lstProduct?.order_date}
            </div>
          </div>
          <div className="flex justify-end">
            <button className=" px-5 py-1 bg-[#CC0403] text-white font-bebas-mon text-[18px] mt-1 rounded-xl">
              XEM LẠI HÓA ĐƠN
            </button>
          </div>
        </div>
      </div>
    </>
  );
}
