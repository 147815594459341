export default function StartLoading({
  width,
  title,
  gift,
}: {
  width: number;
  title: string;
  gift: string;
}) {
  return (
    <div className="absolute left-1/2 -translate-x-1/2 loading-container z-20">
      <div
        className="loading-bar border-loading-game relative "
        style={{
          border: "1px solid #CC0403",
        }}
      >
        <div className="bubbles" style={{ width: width + "%" }}></div>
      </div>
      <p className="loading-text relative -top-2">{title}</p>
      {gift && (
        <div className="absolute -right-[32px] -top-[20%] -translate-y-0 w-10 h-10 bg-[#365e7c] rounded-full border-loading-game">
          <img src={gift} className="w-full absolute bottom-1" />
        </div>
      )}
    </div>
  );
}
