import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import ICONPHONE from "../../assets/image/Icon/icon-phone.png";
import ICON_DOTS from "../../assets/image/TakeAPhoto/icon-dots.svg";
import ICON_DOTS_PRIMARY from "../../assets/image/TakeAPhoto/icon-dots-primary.svg";
import BTN_NEXT_GUIDE from "../../assets/image/TakeAPhoto/btn-cancel-guide.svg";
import B1_TAKEAPHOTO from "../../assets/image/TakeAPhoto/b1-takeaphoto.png";
import B2_TAKEAPHOTO from "../../assets/image/TakeAPhoto/b2-takeaphoto.png";
import B3_TAKEAPHOTO from "../../assets/image/TakeAPhoto/b3-takeaphoto.png";
import CarouselTakeAPhoto from "./CarouselTakeAPhoto";
import ICON_EXPLAIN from "../../assets/image/TakeAPhoto/icon-explain.svg";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import { Fragment, useEffect, useRef, useState } from "react";
import Compressor from "compressorjs";
import { format } from "date-fns";
import uuid from "react-uuid";
import "./GuideTakePhoto.css";
import { SET_CHECK_CAM } from "../../services/localService/localService";
import CAM from "../../assets/image/TakeAPhoto/cam.png";
import ICON_BACK from "../../assets/image/TakeAPhoto/icon-back.png";
import { apiNodeJS, URL_API_UPLOAD_GCS } from "../../services/api/apiNode";
import { homeServices } from "../../services/apiService/homeService";
import { Camera } from "../../components/Camera";
import { getOS } from "../../utils/deviceModel";
import Waiting from "../../components/CountDraw/Waiting";
import ErrorBill from "../../components/Popup/ErrorBill";
import { ListReceiptServices } from "../../services/apiService/ListReceiveService";

const images = [
  {
    id: 1,
    url: B1_TAKEAPHOTO,
    title:
      "Bill bị TAY CHE, THIẾU/ MẤT THÔNG TIN (tên siêu thị, ngày mua hàng, số Hóa đơn, tên Sản Phẩm)",
  },
  {
    id: 2,
    url: B3_TAKEAPHOTO,
    title: "Chụp MỜ, RUNG - KHÔNG  RÕ NÉT",
  },
  {
    id: 3,
    url: B2_TAKEAPHOTO,
    title: "Gấp hóa đơn lại để hiển thị rõ phần tên sản phẩm và số lượng.",
  },
];
export default function GuideTakeAPhoto() {
  const [imageFile, setimageFile] = useState(undefined);
  const [isUpload, setIsUpload] = useState(false);
  const [width, setWidth] = useState();
  const [current, setCurrent] = useState("0");
  const [devices, setDevices] = useState([]);
  const { hotline } = JSON.parse(
    localStorage.getItem("HOME_DATA_LOCAL") || "{}"
  );
  const review_image = localStorage.getItem("REVIEW_IMG");

  const navigation = useNavigate();

  let { campaignId } = useParams();
  let refInputUpload = useRef(null);

  const handleIndex = (id, index) => {
    setActiveDeviceId(id);
    setCurrent(index);
  };
  useEffect(() => {
    window.scrollTo(0, 0);
    setWidth(window.screen.availWidth);
  }, []);

  const handleChangeImage = (event) => {
    let fileUploaded = event.target.files[0];
    const fileUploadedSize = fileUploaded.size / 1024 / 1024;
    if (fileUploadedSize > 20) {
      new Compressor(fileUploaded, {
        quality: 0.4, // 0.6 can also be used, but its not recommended to go below.
        success: (res) => {
          setimageFile(res);
        },
      });
    } else if (fileUploadedSize > 10 && fileUploadedSize <= 20) {
      new Compressor(fileUploaded, {
        quality: 0.5, // 0.6 can also be used, but its not recommended to go below.
        success: (res) => {
          setimageFile(res);
        },
      });
    } else if (fileUploadedSize > 6 && fileUploadedSize <= 10) {
      new Compressor(fileUploaded, {
        quality: 0.7, // 0.6 can also be used, but its not recommended to go below.
        success: (res) => {
          setimageFile(res);
        },
      });
    } else if (fileUploadedSize > 3 && fileUploadedSize <= 6) {
      new Compressor(fileUploaded, {
        quality: 0.8, // 0.6 can also be used, but its not recommended to go below.
        success: (res) => {
          setimageFile(res);
        },
      });
    } else {
      setimageFile(fileUploaded);
    }
  };

  useEffect(() => {
    homeServices
      .ocrEndPointApi(campaignId !== undefined ? campaignId : 0)
      .then((res) => {
        console.log(res);
        setOcrMethod(res);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [campaignId]);
  const [isWaiting, setIsWaiting] = useState(false);
  const [ocrMethod, setOcrMethod] = useState();
  const [messError, setMessError] = useState("");
  const [isError, setIsError] = useState(false);

  const pushImageToGCS = () => {
    setIsWaiting(true);
    let formDataGCS = new FormData();
    formDataGCS.append("file", imageFile);
    const fileName =
      uuid() +
      "_" +
      format(new Date(), "dd-MM-yyyy-HH-mm-ss") +
      "_" +
      imageFile.name;
    formDataGCS.append("fileName", fileName);
    formDataGCS.append(
      "ocrBase",
      ocrMethod?.ocr_endpoint
        ? ocrMethod?.ocr_endpoint
        : "http://ec2-13-250-133-136.ap-southeast-1.compute.amazonaws.com/api/ocr/scan?sm=auto"
    );
    apiNodeJS
      .postUploadToNode(URL_API_UPLOAD_GCS, formDataGCS)
      .then((res) => {
        console.log(res);
        const params = {
          ocr_result: `{}`,
          ocr_endpoint: ocrMethod?.ocr_endpoint
            ? ocrMethod?.ocr_endpoint
            : "http://ec2-13-250-133-136.ap-southeast-1.compute.amazonaws.com/api/ocr/scan?sm=auto",
          gsutil_url: res?.gsutil_url,
          public_url: res?.public_url,
          ocr_method: ocrMethod?.ocr_method,
          request_id: uuid() + "-" + format(new Date(), "ddMMyyyyHHmmss"),
          receipt_datetime: format(new Date(), "yyyy-MM-dd HH:mm:ss"),
        };
        console.log(params);
        localStorage.setItem("REVIEW_IMG", params?.public_url);

        homeServices
          .submitReceiptApi(params)
          .then((res) => {
            console.log(res);
            localStorage.setItem("SALE_ID", res?.sale_id);
            if (res?.auto) {
              const params = {
                sale_id: res?.sale_id,
                show_gift_immediately: true,
              };
              homeServices
                .customerConfirmApi(params)
                .then((response) => {
                  console.log(response);
                  const gifts = response?.rewards[0]?.gifts;
                  localStorage.setItem("LIST_GIFT", JSON.stringify(gifts));
                  navigation(`/video-game/${res?.sale_id}`);
                })
                .catch((err) => {
                  console.log(err);
                })
                .finally(() => {
                  // localStorage.removeItem("SALE_ID");
                });
            } else {
              navigation(`/countdown`);
            }
          })
          .catch((err) => {
            console.log(err);
            setMessError(err);
            setIsError(true);
            setIsWaiting(false);
          })
          .finally((err) => {
            console.log(err);
          });
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const onClickDeleteImg = (e) => {
    setimageFile(undefined);
    setImage(undefined);
    setOpenCam(true);
  };

  const [image, setImage] = useState(undefined);
  const [activeDeviceId, setActiveDeviceId] = useState(undefined);
  const [openCam, setOpenCam] = useState(false);
  const os = getOS();

  const openCamera = () => {
    const data = {
      page: 1,
      limit: 10,
      start_date: "",
      end_date: "",
    };
    ListReceiptServices.GetListHistory(data)
      .then((res) => {
        console.log(res);

        setOpenCam(true);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  function urltoFile(url, filename, mimeType) {
    return fetch(url)
      .then(function (res) {
        return res.arrayBuffer();
      })
      .then(function (buf) {
        return new File([buf], filename, { type: mimeType });
      });
  }
  const getDeviceId = async () => {
    const devices = await navigator.mediaDevices.enumerateDevices();
    const videoDevices = devices.filter((i) => i.kind == "videoinput");
    const font = ["Webcam", "back", "Camera mặt sau", "Back", "cực rộng"];
    const matching = videoDevices.filter((l) => {
      return font.some((term) => l.label.includes(term));
    });

    setDevices(matching?.reverse());
  };
  useEffect(() => {
    getDeviceId();
  }, []);
  useEffect(() => {
    if (os !== "iOS") {
      setTimeout(() => {
        setActiveDeviceId(devices[devices.length - 1]?.deviceId);
      }, 650);
    }
  }, [devices]);
  useEffect(() => {
    if (image !== undefined) {
      urltoFile(image, uuid() + uuid() + ".jpg", "image/jpeg").then(function (
        file
      ) {
        console.log(file);
        setimageFile(file);
      });
    }
  }, [image]);
  const camera = useRef(null);

  const handleCancelCam = () => {
    setimageFile(undefined);
    setImage(undefined);
    setOpenCam(false);
  };
  const handleClose = () => {
    setIsError(false);
    navigation("/");
  };
  return (
    <>
      {isWaiting ? <Waiting /> : ""}
      {openCam ? (
        <div className="popup-box-cam" style={{}}>
          {image === undefined ? (
            <>
              <Camera
                ref={camera}
                aspectRatio={
                  activeDeviceId
                    ? 8 / 15
                    : os === "iOS"
                    ? width < 392
                      ? 8 / 15
                      : 7 / 13
                    : 8 / 15
                }
                videoSourceDeviceId={activeDeviceId}
                facingMode="environment"
                errorMessages={{
                  noCameraAccessible: "",
                  permissionDenied:
                    "Permission denied. Please refresh and give camera permission.",
                  switchCamera:
                    "It is not possible to switch camera to different one because there is only one video device accessible.",
                  canvas: "Canvas is not supported.",
                }}
                videoReadyCallback={() => {
                  console.log("Video feed ready.");
                  localStorage.setItem(SET_CHECK_CAM, true);
                }}
              />
              {os === "iOS" ? null : (
                <div className="relative flex justify-between items-center w-28 bottom-44 bg-black px-4 rounded-3xl opacity-50">
                  {devices.map((d, index) => (
                    <div
                      key={index}
                      className={`${
                        parseInt(current) === index ? "bg-white" : ""
                      } text-[12px] w-8 rounded-2xl h-8 flex justify-between 
                      items-center opacity-100`}
                    >
                      <div className="flex justify-center flex-auto ">
                        <button
                          onClick={() => handleIndex(d.deviceId, index)}
                          className={`${
                            parseInt(current) === index
                              ? "text-black"
                              : "text-white"
                          } font-bold-mon opacity-100`}
                        >
                          {d.label.includes("camera2 2")
                            ? "0.5x"
                            : d.label.includes("camera2 0")
                            ? "1x"
                            : "2x"}
                        </button>
                      </div>
                    </div>
                  ))}
                </div>
              )}
              <div
                style={{
                  backgroundColor: "#333333",
                  width: "100%",
                  height: "200px",
                  position: "relative",
                  zIndex: "20",
                }}
              >
                <div
                  style={{
                    backgroundColor: "#333333",
                    opacity: 0.9,
                    borderTopLeftRadius: "40px",
                    borderTopRightRadius: "40px",
                    height: "80%",
                    display: "flex",
                    justifyContent: "center",
                    alignContent: "center",
                    alignItems: "center",
                    position: "relative",
                    zIndex: "0",
                    top: "-150px",
                  }}
                >
                  <div
                    className=""
                    style={{
                      position: "absolute",
                      top: "15px",
                      width: "90px",
                      height: "90px",
                      borderRadius: "32px",
                    }}
                  >
                    <button
                      style={{
                        position: "relative",
                        top: "-6px",
                        zIndex: "50",
                        backgroundColor: "#F3AB1F",
                        opacity: 1,
                      }}
                      className="btn-webcam "
                      onClick={() => {
                        if (camera.current) {
                          setOpenCam(false);
                          const photo = camera.current.takePhoto();
                          setImage(photo);
                        }
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          marginTop: "8px",
                          justifyContent: "center",
                          alignItems: "center",
                          justifyItems: "center",
                        }}
                      >
                        <img
                          src={CAM}
                          className="w-9 mb-3 relative z-50 opacity-100"
                          style={{ textAlign: "center" }}
                        />
                      </div>
                    </button>
                  </div>
                  <button
                    style={{
                      position: "absolute",
                      opacity: 1,
                      top: "30px",
                      right: "30px",
                      width: "50px",
                      height: "50px",
                      padding: "px 3px",
                      borderRadius: "100px",
                      fontSize: "22px",
                      color: "black",
                      backgroundColor: "#f8c158",
                      fontWeight: "700",
                    }}
                    className="font-bebas-mon"
                    onClick={handleCancelCam}
                  >
                    Đóng
                  </button>
                </div>
              </div>
            </>
          ) : (
            <></>
          )}
        </div>
      ) : (
        <>
          {imageFile !== undefined ? (
            <>
              {isError ? (
                <ErrorBill
                  title={`${"Hóa đơn không hợp lệ"}`}
                  isShow={isError}
                  messError={messError}
                  handleClose={handleClose}
                  labelBtn="Đồng ý"
                  imageError={review_image}
                />
              ) : (
                <Fragment>
                  {!isWaiting && (
                    <div className="relative top-0 font-bebas-mon w-full">
                      <img
                        src={image}
                        className=" w-full min-h-screen h-full max-h-[680px]"
                        alt="upload view"
                      />
                      <div className="absolute bottom-32 w-full flex justify-center gap-[12px] p-4">
                        <button
                          onClick={(e) => onClickDeleteImg(e)}
                          className="bg-[#F3AB1F] border-0 text-[#fff] px-[40px] py-[13px] text-center rounded-[13px] text-[24px]"
                        >
                          Chụp lại
                        </button>
                        <button
                          onClick={() => {
                            pushImageToGCS();
                          }}
                          className="bg-[#CC0403] border-0 text-[#FFFFFF] px-[40px] py-[13px] text-center rounded-[13px] text-[24px]"
                        >
                          Xác nhận
                        </button>
                      </div>
                    </div>
                  )}
                </Fragment>
              )}{" "}
            </>
          ) : (
            <>
              <div className={`relative ${isUpload ? "is-upload" : ""} `}>
                <div className="bg-guide">
                  <div className="bg-bottom-guide flex flex-col items-center bg-white rounded-t-[32px] backdrop-blur-sm">
                    {imageFile !== undefined ? (
                      ""
                    ) : (
                      <>
                        <div className="font-bebas-mon uppercase mt-6 text-[35px] text-center leading-7 mb-1">
                          hướng dẫn chụp hóa đơn
                        </div>
                        <div className="w-full px-5 flex flex-col justify-center items-center">
                          <div className="w-full">
                            <Carousel
                              className="style-carousel"
                              autoPlay
                              centerMode={false}
                              showArrows={false}
                              infiniteLoop={true}
                              showThumbs={false}
                              showStatus={false}
                              preventMovementUntilSwipeScrollTolerance={false}
                              renderIndicator={(
                                onClickHandler,
                                isSelected,
                                index,
                                label
                              ) => {
                                const defStyle = (
                                  <img src={ICON_DOTS} className="w-3 h-3" />
                                );
                                const defStylePrimary = (
                                  <img
                                    src={ICON_DOTS_PRIMARY}
                                    className="w-3 h-3"
                                  />
                                );
                                const style = isSelected
                                  ? { ...defStylePrimary }
                                  : { ...defStyle };
                                return (
                                  <span
                                    className="inline-block px-2"
                                    onClick={onClickHandler}
                                    onKeyDown={onClickHandler}
                                    value={index}
                                    key={index}
                                    role="button"
                                    tabIndex={0}
                                    aria-label={`${label} ${index + 1}`}
                                  >
                                    {style}
                                  </span>
                                );
                              }}
                            >
                              {images.map((item) => (
                                <div key={item.id}>
                                  <CarouselTakeAPhoto item={item} />
                                </div>
                              ))}
                            </Carousel>
                          </div>
                          <div className="bg-[#FFFDEE] mt-[120px] pb-3 w-full mx-6 rounded-xl">
                            <div className="flex ">
                              <div className="w-16 mt-3 flex items-start justify-center">
                                <img src={ICON_EXPLAIN} />
                              </div>
                              <div className="w-full mt-3">
                                <div className="title-description-bill">
                                  Lưu ý{" "}
                                </div>
                                <div className="content-description-bill mt-1 ml-[2px]">
                                  Không gấp hoặc che các thông tin ở phần đầu và
                                  cuối hóa đơn.
                                </div>
                              </div>
                            </div>
                            <div className="flex ">
                              <div className="w-16 flex items-start justify-center">
                                <img src={ICONPHONE} />
                              </div>
                              <div className="w-full">
                                <div className="content-description-bill">
                                  Liên hệ Hotline để được hướng dẫn chi tiết Số
                                  hotline{" "}
                                  <a
                                    href={`tel:${hotline}`}
                                    className="text-[#333333] font-bold-mon"
                                  >
                                    {hotline}
                                  </a>{" "}
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="flex mt-[15px] justify-between mb-[80px]">
                            <div className="color-button-disable btn-takephoto mr-2 ">
                              <div className="flex flex-col justify-center w-3 ml-3">
                                <img src={ICON_BACK} />
                              </div>
                              <NavLink
                                to={`/`}
                                className="font-bebas-mon btn-text justify-center flex items-center text-[22px] -ml-3"
                              >
                                Trở lại
                              </NavLink>
                            </div>
                            <div className="color-button-enable btn-takephoto ml-2 ">
                              <div className="flex flex-col justify-center">
                                <img
                                  src={BTN_NEXT_GUIDE}
                                  className=" h-6 w-5 ml-1"
                                />
                              </div>
                              <input
                                type="file"
                                hidden
                                id="actual-btn"
                                className="w-full"
                                style={{ display: "none" }}
                                onChange={(e) => handleChangeImage(e)}
                                capture
                                accept="image/*"
                                ref={refInputUpload}
                              />
                              <button
                                onClick={() => openCamera()}
                                className="font-bebas-mon btn-text text-white flex justify-center items-center -ml-4 text-[22px] leading-6"
                              >
                                Bỏ qua
                                <br /> hướng dẫn
                              </button>
                            </div>
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </>
          )}
        </>
      )}
    </>
  );
}
