import { NavLink, useNavigate } from "react-router-dom";
import BACK from "../../assets/image/Header/back-header.png";
import { useEffect, useState } from "react";
import SkelatonRotation from "../../components/Skelaton/SkelatonRotation";
import HeaderBackground from "../../components/HeaderBackground/HeaderBackground";
import { ListLuckyDraw } from "../../models/ListRotation";
import { setAuthorization } from "../../services/apiService/configURL";
import { userLocal } from "../../services/localService/localService";
import { GameServices } from "../../services/apiService/GameService";
import MainNavBar from "../../components/NavBar/MainNavBar";
import { homeServices } from "../../services/apiService/homeService";
import ContactPhoneZalo from "../../components/ContactPhoneZalo/ContactPhoneZalo";

export default function ListGame() {
  const navigation = useNavigate();
  const [listRotation, setListRotation] = useState<[ListLuckyDraw]>();
  const [page, setPage] = useState<number>(1);
  const [limit, setLimit] = useState<number>(10);
  const [totalPage, setTotalPage] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  let { token } = userLocal.get();
  setAuthorization(token);

  useEffect(() => {
    setIsLoading(true);
    getWinCampaign("", page, limit);
    localStorage.removeItem("GAME_DATA_LOCAL");
    localStorage.removeItem("LUOT_QUAY");
    localStorage.removeItem("SALE_ID");
    localStorage.removeItem("GIFT_WHEEL");
  }, []);
  const getWinCampaign = (status: string, page: number, limit: number) => {
    GameServices.ListRotation("", page, limit)
      .then((res: any) => {
        console.log(res);
        setListRotation(res.play_list);
        setTotalPage(res?.total_item);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  const handleRedirect = (so_id: number) => {
    console.log(so_id);
    const params = {
      sale_id: so_id,
      show_gift_immediately: true,
    };
    homeServices
      .customerConfirmApi(params)
      .then((res: any) => {
        console.log(res);
        const gifts = res?.rewards[0]?.gifts;
        localStorage.setItem("LIST_GIFT", JSON.stringify(gifts));
        navigation(`/video-game/${so_id}`);
        // lstPrize(res?.rewards[0]?.gifts);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        // localStorage.removeItem("SALE_ID");
      });
  };
  const handleToarstErr = () => {};
  return (
    <div>
      <HeaderBackground title="Lượt đổi thưởng" buttonBack="/" />
      <div className=" mb-5">
        <div className="containerNotify__background absolute bottom-0 h-[87%] w-full z-0">
          <ul className="containerNotify__background-listnoti relative z-0 pt-7 box-border ">
            {listRotation ? (
              <div className="mb-20">
                {(listRotation?.length as number) !== 0 ? (
                  listRotation?.map((item: ListLuckyDraw, index: number) => (
                    <div key={index}>
                      <li
                        key={index}
                        className={`mx-[25px] rounded-2xl flex items-center h-[126px] bg-[#F0F0F0] mb-4 overflow-hidden`}
                        onClick={
                          item.play_no > 0
                            ? () => handleRedirect(item.sale_id)
                            : handleToarstErr
                        }
                      >
                        <div className="w-full flex">
                          <div className="flex">
                            <div className=" px-[2px] grid grid-cols-9 gap-2">
                              <div className="col-span-3">
                                <img
                                  src={item.banner_url}
                                  className="image-product w-[100px] h-[100px] ml-2 text-center rounded-lg"
                                  alt="Image"
                                />
                              </div>
                              <div className="col-span-6 gird grid-cols-1 relative -ml-[5px] pl-3">
                                <div className="col-span-1 text-reponsive w-full text-[13px] leading-[18px] font-semibold-mon">
                                  {item.campaign_name}
                                  <br />
                                </div>
                                <div className="col-span-1 text-[13px]">
                                  {item.play_no > 0 ? (
                                    <NavLink
                                      to={`/video-game/${item.sale_id}`}
                                      className="text-reponsive font-regular-mon leading-[20px]  text-[#828282] "
                                    >
                                      Bạn có{" "}
                                      <span className="text-[#CC0403] font-bold-mon">
                                        {item.play_no}
                                      </span>{" "}
                                      lượt{" "}
                                      <span className="text-[#CC0403] text-[11px] font-semibold-mon italic">
                                        (Nhấn chơi ngay)
                                      </span>
                                    </NavLink>
                                  ) : (
                                    <div
                                      // onClick={() => {
                                      //   handleToarstErr();
                                      // }}
                                      className=" text-reponsive font-regular-mon leading-[20px]  text-[#828282] "
                                    >
                                      Bạn có{" "}
                                      <span className="text-[#CC0403] font-bold-mon">
                                        {item.play_no}
                                      </span>{" "}
                                      lượt đổi thưởng
                                    </div>
                                  )}
                                </div>
                                <div className="text-reponsive-date text-[#828282]">
                                  Hết hạn ngày{" "}
                                  <span className="text-[#CC0403] font-bold-mon">
                                    {item.expired_date}
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          className={`flex flex-col justify-end items-end align-bottom bottom-0 h-full
               ${item.play_no > 0 ? "" : "filter grayscale"}`}
                        >
                          {item.play_no > 0 ? (
                            <NavLink
                              to={`/video-game/${item.sale_id}`}
                              className=" bgr-icon-back flex background-bottom-icon w-14 h-14 z-30"
                            >
                              <div className="icon-bottom w-6 h-6 z-40 mt-5 ml-5"></div>
                            </NavLink>
                          ) : (
                            <div className=" bgr-icon-back flex background-bottom-icon w-14 h-14 z-30">
                              <div className="icon-bottom w-6 h-6 z-40 mt-5 ml-5"></div>
                            </div>
                          )}
                        </div>
                      </li>
                    </div>
                  ))
                ) : (
                  <div className="mt-40 text-center px-10">
                    Tiếc quá, bạn chưa có lượt đổi thưởng nào. Tham gia ngay các
                    chương trình khuyến mãi để nhận thêm nhiều phần quà hấp dẫn
                    nhé!
                  </div>
                )}
              </div>
            ) : (
              <div>
                <SkelatonRotation />
                <SkelatonRotation />
                <SkelatonRotation />
                <SkelatonRotation />
                <SkelatonRotation />
                <SkelatonRotation />
              </div>
            )}
          </ul>
        </div>
      </div>
      <ContactPhoneZalo />

      <MainNavBar />
    </div>
  );
}
