import PHONE from "../../assets/image/Icon/phone.png";

export default function ContactPhoneZalo() {
  const { hotline } = JSON.parse(
    localStorage.getItem("HOME_DATA_LOCAL") || "{}"
  );
  return (
    <div className="fixed bottom-32 z-50 right-2 flex flex-col justify-center items-center">
      <a href={`tel:${hotline}`}>
        <img src={PHONE} className="w-14" />
      </a>
    </div>
  );
}
