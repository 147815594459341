import { useLocation, useNavigate } from "react-router-dom";
import GAME_NAVBAR from "../../assets/image/NavBar/game-navbar.png";
import GIFT_NAVBAR from "../../assets/image/NavBar/gift-navbar.png";
import HOME_NAVBAR from "../../assets/image/NavBar/home-navbar.png";
import ICON_HOME_ENABLE from "../../assets/image/Icon/home.png";
import ICON_GAME_ENABLE from "../../assets/image/Icon/game.png";
import ICON_GIFT_ENABLE from "../../assets/image/Icon/gift.png";
import GAME_DISABLE from "../../assets/image/NavBar/game.png";
import GIFT_DISABLE from "../../assets/image/NavBar/gift.png";
import HOME_DISABLE from "../../assets/image/NavBar/home.png";
import Logo from "./Logo";

export default function MainNavBar() {
  const navigation = useNavigate();
  const location = useLocation();
  const data = JSON.parse(localStorage.getItem("HOME_DATA_LOCAL") || "{}");
  console.log(data);
  const handleRedirect = (type: string) => {
    navigation(type);
  };
  return (
    <div className="fixed bottom-0">
      <div className="relative top-0">
        {location?.pathname === "/" && <img src={HOME_NAVBAR} />}
        {location?.pathname === "/games" && <img src={GAME_NAVBAR} />}
        {location?.pathname === "/gifts" && <img src={GIFT_NAVBAR} />}
        <div className="absolute -top-1/2 translate-y-1/2 w-full grid grid-cols-3">
          {location?.pathname === "/games" ? (
            <div className="relative rounded-full left-[56%] -translate-x-[43%] -top-[50%] translate-y-1/2 bg-[#CC0403] p-5 w-[15vw]">
              <div className="relative flex justify-center">
                <img src={ICON_GAME_ENABLE} className="w-[150%]" />
              </div>
            </div>
          ) : (
            <div
              className="relative top-[80%] -translate-y-1/2"
              onClick={() => handleRedirect("/games")}
            >
              <img
                src={GAME_DISABLE}
                className="relative top-1/2 -translate-y-1/2 left-1/2 -translate-x-1/2"
              />
              {parseInt(data?.remaining_draw) > 0 && (
                <div className="absolute text-[14px] left-[62%] bg-[#F3AB1F] -translate-x-1/2 -top-1 w-6 flex justify-center items-center rounded-full font-semibold-mon h-6">
                  {data?.remaining_draw}
                </div>
              )}
            </div>
          )}
          {location?.pathname === "/" ? (
            <div className="relative rounded-full left-1/2 -translate-x-1/2 bg-[#CC0403] p-5 w-[15vw]">
              <div className="relative flex justify-center">
                <img src={ICON_HOME_ENABLE} className="w-[150%]" />
              </div>
            </div>
          ) : (
            <div
              className="relative top-[80%] -translate-y-1/2"
              onClick={() => handleRedirect("/")}
            >
              <img
                src={HOME_DISABLE}
                className="relative top-1/2 -translate-y-1/2 left-1/2 -translate-x-1/2"
              />
            </div>
          )}
          {location?.pathname === "/gifts" ? (
            <div className="relative rounded-full -top-[50%] left-[45%] -translate-x-[57%] translate-y-1/2 bg-[#CC0403] p-5 w-[15vw]">
              <div className="relative flex justify-center">
                <img src={ICON_GIFT_ENABLE} className="w-[150%]" />
              </div>
            </div>
          ) : (
            <div
              className="relative top-[80%] -translate-y-1/2"
              onClick={() => handleRedirect("/gifts")}
            >
              <img
                src={GIFT_DISABLE}
                className="relative top-1/2 -translate-y-1/2 left-1/2 -translate-x-1/2"
              />
            </div>
          )}
        </div>
      </div>
      <div className="grid grid-cols-3 absolute bottom-[12px] w-full text-center">
        <div
          style={{
            transform:
              location?.pathname === "/games"
                ? "translateX(-41%)"
                : "translateX(-50%)",
            color: location?.pathname === "/games" ? "#CC0403" : "#8A8A8A",
          }}
          className="relative left-[50%] font-bebas-mon text-[#8A8A8A] text-[20px]"
          onClick={() => handleRedirect("/games")}
        >
          Lượt đổi thưởng
        </div>
        <div
          style={{
            color: location?.pathname === "/" ? "#CC0403" : "#8A8A8A",
          }}
          className="font-bebas-mon text-[#8A8A8A] text-[20px]"
          onClick={() => handleRedirect("/")}
        >
          Trang chủ
        </div>
        <div
          style={{
            transform:
              location?.pathname === "/gifts"
                ? "translateX(-57%)"
                : "translateX(-50%)",
            color: location?.pathname === "/gifts" ? "#CC0403" : "#8A8A8A",
          }}
          className="relative left-[50%] font-bebas-mon text-[20px]"
          onClick={() => handleRedirect("/gifts")}
        >
          Quà của tôi
        </div>
      </div>
      <Logo />
    </div>
  );
}
