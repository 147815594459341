// import HeaderBackground from "../../component/HeaderBackground/HeaderBackground";
// import ICON_NOTIFY from "../../assets/image/icon_notify.svg";
// import NavbarHome from "../../component/NavbarHome/NavbarHome";
import ICON_HISTORY_PARTICI from "../../assets/image/Icon/icon-history-participate.svg";
import ICON_MYGIFT from "../../assets/image/Icon/icon-mygift.svg";
// import "../../assets/css/inforCustomer.css";
import ICON_RIGHT from "../../assets/image/Icon/icon-right.svg";
// import IconPhoneAndZalo from "../../component/IconPhoneAndZalo/IconPhoneAndZalo";
import { useState } from "react";
import LIST_GIFT from "../../assets/image/Icon/list_gift.svg";
import { useNavigate } from "react-router-dom";
// import FooterLogo from "../../component/Footer/FooterLogo";
import MY_GAME from "../../assets/image/Icon/mygame.svg";
import { useEffect } from "react";
// import { homeServices } from "../../services/apiService/homeServices";
import LOGOUT from "../../assets/image/Icon/Logout.png";
import HeaderBackground from "../../components/HeaderBackground/HeaderBackground";
import PopupNotify from "../../components/Popup/PopupError";
import Logo from "../../components/NavBar/Logo";
import ContactPhoneZalo from "../../components/ContactPhoneZalo/ContactPhoneZalo";
// import PopupNotify from "../../component/PopupNoti/PopupNoti";

const TITLE = "tài khoản";
const clickFilter = "/list-notify";
export default function InforCustomer() {
  let appCode = window.location.pathname.split("/")[1];
  localStorage.setItem("linkRedirect", `/infor-customer`);
  const navigation = useNavigate();
  const [campaign_id, setCampaignId] = useState("");
  const [listCampaign, setListCampaign] = useState();
  const [isLogin, setIsLogin] = useState(false);
  const handleMyGift = () => {
    navigation(`/gifts`);
  };
  const handleHistory = () => {
    navigation(`/list-history`);
  };
  const handlePrize = () => {
    navigation("/prize");
  };
  const handleListGift = () => {
    navigation(`/list-infor-customer`);
  };

  const handleLogout = () => {
    setIsLogin(true);
  };
  const handleOk = () => {
    // homeServices
    // .getLogout()
    // .then((res) => {
    //   console.log(res);
    localStorage.clear();
    navigation("/login");
    // })
    // .catch((err) => {
    //   console.log(err);
    // });
  };
  const handleCancel = () => {
    setIsLogin(false);
  };
  return (
    <div>
      <HeaderBackground title={TITLE} buttonBack={`/`} />
      <div className="containerNotify__background bg-[#fff] absolute rounded-[30px_30px_0_0] bottom-0 h-[87%] w-full z-10">
        <ul className="containerNotify__background-list mt-8 mx-6 box-border z-20 grid-rows-2 border-2 rounded-lg">
          <li className="content-info-li border-b-2" onClick={handleMyGift}>
            <div className="icon-infor-li -ml-1">
              <img src={ICON_MYGIFT} />
            </div>
            <div className=" -ml-[2px]">Quà của tôi</div>
            <div className="ml-auto mr-6">
              <img src={ICON_RIGHT} />
            </div>
          </li>
          <li className="content-info-li border-b-2" onClick={handleListGift}>
            <div className="icon-infor-li">
              <img src={LIST_GIFT} />
            </div>
            <div>Danh sách quà tặng</div>
            <div className="ml-auto mr-6">
              <img src={ICON_RIGHT} />
            </div>
          </li>
          <li className="content-info-li border-b-2" onClick={handleHistory}>
            <div className="icon-infor-li -ml-1">
              <img src={ICON_HISTORY_PARTICI} />
            </div>
            <div>Lịch sử tham gia</div>
            <div className="ml-auto mr-6">
              <img src={ICON_RIGHT} />
            </div>
          </li>
          <li className="content-info-li border-b-2" onClick={handlePrize}>
            <div className="icon-infor-li">
              <img src={MY_GAME} />
            </div>
            <div>Thể lệ chương trình</div>
            <div className="ml-auto mr-6">
              <img src={ICON_RIGHT} />
            </div>
          </li>
          <li className="content-info-li " onClick={handleLogout}>
            <div className="icon-infor-li">
              <img src={LOGOUT} />
            </div>
            <div>Đăng xuất</div>
            <div className="ml-auto mr-6">
              <img src={ICON_RIGHT} />
            </div>
          </li>
        </ul>
      </div>
      {/* {isLogin ? ( */}
      <PopupNotify
        isShow={isLogin}
        notify={"Thông báo"}
        content={"Bạn muốn đăng xuất khỏi hệ thống?"}
        handleOk={handleOk}
        handleCancel={handleCancel}
        labelButtonOK="Đồng ý"
        labelButtonCancel="Đóng"
        // handleCancel={handleCancel}
        // twoButton={true}
      />
      <ContactPhoneZalo />
      <Logo />
    </div>
  );
}
