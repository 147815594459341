import TITLE from "../../assets/image/Popup/title18yol.png";

export default function Popup18yol({
  isShow,
  handleOff,
  handleOn,
}: {
  isShow: boolean;
  handleOff: () => void;
  handleOn: () => void;
}) {
  return (
    <div
      className="fixed z-30 overflow-y-auto top-0 w-full left-0 show"
      id="modal"
    >
      {isShow ? (
        <div className="flex items-center justify-center min-height-100vh px-4 pb-20 text-center sm:block sm:p-0">
          <div className="fixed inset-0 transition-opacity">
            <div className="absolute inset-0 bg-gray-900 opacity-50" />
            <div className="containerNotify__backgrounds-physical">
              <div className="">
                <div className="absolute z-30 content-physical-popup containerNotify__backgrounds-list-physical w-full">
                  <div className="relative top-1/2 px-5">
                    <div className="relative bg-[#E0E0E0] z-0 rounded-t-[80px] pb-5 rounded-b-2xl">
                      <div className="absolute z-40 px-12">
                        <img src={TITLE} className="-mt-12" />
                      </div>
                      <div className=" pt-40">
                        <div className="relative flex justify-around font-bebas-mon text-[20px]">
                          <button
                            className="bg-[#F3AB1F] text-[#CC0403] w-36 py-3 rounded-xl text-[26px] border-btn18"
                            onClick={handleOff}
                          >
                            Đã đủ 18
                          </button>
                          <button
                            className="bg-[#CC0403] text-[#F3AB1F] w-36 rounded-xl text-[26px] border-btn18"
                            onClick={handleOn}
                          >
                            Chưa đủ 18
                          </button>
                        </div>
                        <div className="font-regular-mon text-[16px] px-8 mt-3">
                          Khi bấm chọn{" "}
                          <span className="font-semibold-mon ">ĐÃ ĐỦ 18</span>{" "}
                          đồng nghĩa với việc bạn đã xác nhận mình đủ tuổi uống
                          bia rượu hợp pháp. Sapporo yêu cầu xác nhận độ tuổi
                          nhằm đảm bảo khách hàng có trách nhiệm trong hành vi
                          uống của mình
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
}
