import { useEffect, useState } from "react";
import TabGift from "./TabGift";
import HeaderBackground from "../../components/HeaderBackground/HeaderBackground";
import { ListReceiptServices } from "../../services/apiService/ListReceiveService";
import Logo from "../../components/NavBar/Logo";
import ContactPhoneZalo from "../../components/ContactPhoneZalo/ContactPhoneZalo";
const TITLE = "Danh sách quà tặng";

export default function ListGiftForCustomer() {
  const [dataListGift, setDataListGift] = useState<any>();

  useEffect(() => {
    getPrizeCampaign("1");
  }, []);
  const getPrizeCampaign = (id: string) => {
    ListReceiptServices.ReceiptCustomerGift(id)
      .then((res: any) => {
        console.log(res);
        setDataListGift(res?.gifts);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  return (
    <div>
      <HeaderBackground title={TITLE} buttonBack={"/infor-customer"} />
      <div className="containerNotify__background bg-[#fff] absolute rounded-[30px_30px_0_0] bottom-0 h-[87%] w-full z-10">
        <div className="containerNotify__background-list font-light-mon box-border z-20">
          <div className=" box-border mx-3 mt-5 ">
            <table className="table-auto w-full text-[12px] leading-4 rounded-t-md">
              <thead className=" h-8 rounded-t-md">
                <tr className=" text-white font-light-mon rounded-t-2xl">
                  <th className="border-head rounded-tl-xl pl-5 text-left">
                    Tên quà
                  </th>
                  <th className="border-head-totalgift">Tổng quà</th>
                  <th className="border-head">Đã ra</th>
                  <th className="border-head rounded-tr-xl">Còn lại</th>
                </tr>
              </thead>
              <tbody className="font-bold-mon">
                {dataListGift?.map((item: any, index: number) => (
                  <TabGift item={item} key={index} index={index} />
                ))}
              </tbody>
            </table>
          </div>
        </div>
        <div className="h-40"></div>
        <div className="h-40"></div>
      </div>
      <Logo />
      <ContactPhoneZalo />
    </div>
  );
}
