import { GameDetailsModel } from "../../models/GameModel";

export const HOME_DATA_LOCAL = "HOME_DATA_LOCAL";
export const LOCATION_PATH_BEFORE = "LOCATION_PATH_BEFORE";
export const WHEEL_PHANTHUONG = "WHEEL_PHANTHUONG";
export const WHEEL_HAS_LUOTQUAY = "WHEEL_HAS_LUOTQUAY";
export const WHEEL_LUOTQUAY = "WHEEL_LUOTQUAY";
export const GET_GAME_ID = "GET_GAME_ID";
export const DATA_USER = "DATA_USER";
export const GAME_DATA_LOCAL = "GAME_DATA_LOCAL";
export const SET_CHECK_CAM = "SET_CHECK_CAM";
export const LUOT_QUAY = "LUOT_QUAY";
export const BACK_GLOBAL = "BACK_GLOBAL";

export const homeLocal = {
  set: (homeData: string) => {
    let homeDataJson = JSON.stringify(homeData);
    localStorage.setItem(HOME_DATA_LOCAL, homeDataJson);
  },
  get: () => {
    let homeDataJson = localStorage.getItem(HOME_DATA_LOCAL);
    if (homeDataJson) {
      return JSON.parse(homeDataJson);
    } else {
      return {};
    }
  },
};
export const gameLocal = {
  set: (gameData: GameDetailsModel) => {
    let gameDataJson = JSON.stringify(gameData);
    localStorage.setItem(GAME_DATA_LOCAL, gameDataJson);
  },
  get: () => {
    let gameDataJson = localStorage.getItem(GAME_DATA_LOCAL);
    if (gameDataJson !== "undefined" && gameDataJson !== null) {
      return JSON.parse(gameDataJson || "");
    } else {
      return {};
    }
  },
};
export const userLocal = {
  set: (userData: string) => {
    let userDataJson = JSON.stringify(userData);
    localStorage.setItem(DATA_USER, userDataJson);
  },
  get: () => {
    let userDataJson = localStorage.getItem(DATA_USER);
    if (userDataJson) {
      return JSON.parse(userDataJson);
    } else {
      return {};
    }
  },
};

export const locationBefore = {
  set: (location: string) => {
    let locationJson = JSON.stringify(location);
    localStorage.setItem(LOCATION_PATH_BEFORE, locationJson);
  },
  get: () => {
    let locationJson = localStorage.getItem(LOCATION_PATH_BEFORE);
    if (locationJson) {
      return JSON.parse(locationJson);
    } else {
      return {};
    }
  },
};
