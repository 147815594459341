import { useEffect, useState } from "react";
import TEN_LUA from "../../assets/image/CountDown/countdown.png";
import PHONE from "../../assets/image/CountDown/phone.png";
import { CountdownCircleTimer } from "react-countdown-circle-timer";
import { useNavigate } from "react-router-dom";
import { homeServices } from "../../services/apiService/homeService";
import { ListReceipt } from "../../models/GameModel";
import ErrorBill from "../Popup/ErrorBill";
import ContactPhoneZalo from "../ContactPhoneZalo/ContactPhoneZalo";

export default function CountDown() {
  const { hotline } = JSON.parse(
    localStorage.getItem("HOME_DATA_LOCAL") || "{}"
  );
  const review_image = localStorage.getItem("REVIEW_IMG");
  const sale_id = JSON.parse(localStorage.getItem("SALE_ID") || "{}");
  const navigation = useNavigate();
  const [minutes, setMinutes] = useState(1);
  const [seconds, setSeconds] = useState(0);
  const [count, setCount] = useState(0);
  const [isError, setIsError] = useState(false);
  const [messError, setMessError] = useState("");
  const [checkLast, setCheckLast] = useState(false);
  const [secondCount, setSecondCount] = useState(60);
  useEffect(() => {
    if (!isError) {
      const interval = setInterval(() => {
        if (seconds > 0) {
          setSeconds(seconds - 1);
        }
        if (seconds === 0) {
          if (minutes === 0) {
            if (count === 1) {
              console.log("hello: ", count);
              setMinutes(0);
              setSeconds(0);
              setSecondCount(0);
              clearInterval(interval);
            } else if (count === 0) {
              console.log("hello");
              setMinutes(1);
              setSeconds(0);
              setSecondCount(60);
              setCount(count + 1);
            }
          } else {
            setSeconds(59);
            setMinutes(minutes - 1);
          }
        }
      }, 1000);
      if (
        seconds === 2 ||
        seconds === 10 ||
        seconds === 20 ||
        seconds === 30 ||
        seconds === 40 ||
        seconds === 50 ||
        seconds === 59
      ) {
        let lastCall = false;
        if (minutes === 0 && seconds === 2 && count === 1) {
          lastCall = true;
          setCheckLast(true);
        }
        homeServices
          .RecallSubmitReceiptApi(sale_id as string, lastCall)
          .then((res: any) => {
            console.log(res);
            const checkCampaignSting = res?.rewards?.filter((x: any) =>
              x.game_name.toLowerCase().includes("sting")
            );
            console.log(checkCampaignSting);

            if (res?.auto) {
              const params = {
                sale_id: sale_id,
                show_gift_immediately: true,
              };
              homeServices
                .customerConfirmApi(params)
                .then((res: any) => {
                  console.log(res);
                  const gifts = res?.rewards[0]?.gifts;
                  localStorage.setItem("LIST_GIFT", JSON.stringify(gifts));
                  navigation(`/video-game/${sale_id}`);
                })
                .catch((err) => {
                  console.log(err);
                })
                .finally(() => {
                  // localStorage.removeItem("SALE_ID");
                });
              localStorage.setItem(
                "LIST_GIFT",
                JSON.stringify(res?.rewards[0]?.gifts)
              );
              setMinutes(0);
              setSeconds(0);
              clearInterval(interval);
            }
          })
          .catch((err) => {
            console.log(err);
            setMessError(err);
            setIsError(true);
          });
      }
      return () => {
        clearInterval(interval);
      };
    }
  });
  const renderTime = ({ remainingTime }: any) => {
    const minutes = Math.floor((remainingTime % 3600) / 60);
    const seconds = remainingTime % 60;
    if (remainingTime === 0 && count === 1) {
      return (
        <div className="timer text-[#CC0403] font-semibold-mon text-[20px]">
          Hết giờ ...
        </div>
      );
    }
    return (
      <div className="countdown-text text-[#CC0403] text-[36px] font-bold-mon flex justify-center">{`${minutes}:${
        seconds < 10 ? "0" + seconds : seconds
      }`}</div>
    );
  };
  const handleClose = () => {
    setIsError(false);
    navigation("/");
  };
  return (
    <div className="bg-coundown min-h-screen relative">
      {isError ? (
        <ErrorBill
          title={`${checkLast ? "" : "Hóa đơn không hợp lệ"}`}
          isShow={isError}
          messError={messError}
          handleClose={handleClose}
          labelBtn="Đồng ý"
          imageError={review_image as string}
        />
      ) : (
        <div className="absolute top-1/2 -translate-y-1/2 flex flex-col items-center ">
          <div className="font-bebas-mon text-[40px] text-[#CC0403] text-center flex">
            Vui lòng đợi {count === 1 && "thêm"}
          </div>
          <div
            className={`${count === 1 ? "mt-0" : "mt-10"} flex justify-center `}
          >
            <img src={TEN_LUA} className="w-10/12" />
          </div>
          <div className="font-regular-mon text-[#424242] text-[14px] text-center px-10">
            {count === 0
              ? "Mong quý khách thông cảm, hóa đơn của bạn đang được hệ thống xử lý"
              : "Mong quý khách thông cảm, hệ thống cần thêm thời gian để đọc hóa đơn"}
          </div>
          <div className="mt-16">
            <CountdownCircleTimer
              isPlaying
              duration={secondCount}
              colors={["#CC0403", "#CC0403", "#CC0403", "#bdbdbd"]}
              colorsTime={[secondCount, 6, 3, 0]}
              onComplete={() => ({ shouldRepeat: true, delay: 1 })}
            >
              {renderTime}
            </CountdownCircleTimer>
          </div>
          <div className="flex items-center mt-20">
            <div>
              <img src={PHONE} />
            </div>
            <div className="text-[#333333] font-light-mon ml-2">
              Liên hệ Hotline{" "}
              <span className="font-semibold-mon text-[#CC0403]">
                <a href={`tel:${hotline}`}>{hotline}</a>
              </span>
            </div>
          </div>
        </div>
      )}
      <ContactPhoneZalo />
    </div>
  );
}
