export default function CarouselTakeAPhoto({ item }: { item: any }) {
  return (
    <>
      <div>
        <img
          src={item?.url}
          className="h-[314px] rounded-2xl bg-center bg-cover duration-500"
        />
        <div className="flex w-full mt-5 justify-center items-start rounded-lg">
          <div className="title-description flex justify-center text-center mt-2">
            <div>{item.title}</div>
          </div>
        </div>
        <div className="mt-10 ml-8"></div>
      </div>
    </>
  );
}
