import { useEffect, useState } from "react";
import VIDEO from "../../assets/video/video.mp4";
import Success from "../SuccessPrize/SuccessPrize";

export default function PlayGame() {
  const [isVideo, setVideo] = useState<boolean>(true);
  const lst_gift = JSON.parse(localStorage.getItem("LIST_GIFT") || "{}");

  return (
    <div>
      {isVideo ? (
        <div className="stylevideo">
          <video
            width="100%"
            playsInline
            preload="metadata"
            autoPlay
            disablePictureInPicture
            controls={false}
            onEnded={() => {
              console.log("vaof cuoi");
              setVideo(false);
            }}
          >
            <source src={VIDEO} type="video/mp4" />
          </video>
        </div>
      ) : (
        <Success prize={lst_gift} />
      )}
    </div>
  );
}
