import IMG_TITLE from "../../assets/image/Popup/notify-yellow.png";
import HEADER from "../../assets/image/Popup/popup-tc.png";
import { Fragment } from "react";
import "../../assets/css/Popup.css";

export default function PopupNotify({
  isShow,
  notify,
  content,
  labelButtonOK,
  labelButtonCancel,
  handleOk,
  handleCancel,
  twoButton,
  checkphone,
}: {
  isShow: boolean;
  notify: string;
  content?: any;
  handleOk?: () => void;
  handleCancel?: () => void;
  twoButton?: string;
  checkphone?: string;
  labelButtonOK: string;
  labelButtonCancel?: string;
}) {
  return (
    <Fragment>
      {isShow && (
        <div
          className="fixed z-10 overflow-y-auto top-0 w-full left-0 show"
          id="modal"
        >
          <div className="flex items-center justify-center min-height-100vh px-4 pb-20 text-center sm:block sm:p-0">
            <div className="fixed inset-0 transition-opacity">
              <div className="absolute inset-0 bg-gray-900 opacity-70" />
              <div className="content-popup-condition w-full">
                <div className="mx-6">
                  <div className=" relative -top-20">
                    <img src={HEADER} className="w-full" />
                    <div className="absolute top-11 bg-white w-full min-h-[270px] rounded-3xl px-1">
                      <div className="mt-8 font-bebas-mon text-[36px] text-[#CC0403]">
                        {notify}
                      </div>
                      <div
                        className={`
                          ${checkphone ? "text-justify" : ""}
                           font-regular-mon text-black text-[18px] mt-3 px-[6px]`}
                      >
                        <div
                          dangerouslySetInnerHTML={{
                            __html: content,
                          }}
                        ></div>
                      </div>
                      <div className="mt-6 flex w-full justify-around mb-5">
                        {labelButtonCancel && (
                          <button
                            onClick={handleCancel}
                            className={`
                             bg-[#F8C158] font-bebas-mon text-[25px] text-[#CC0403] px-12 py-2 rounded-lg mb-2"
                          `}
                          >
                            {labelButtonCancel}
                          </button>
                        )}

                        <button
                          onClick={handleOk}
                          className={`
                             bg-[#CC0403] font-bebas-mon text-[25px] text-[#F8C158] px-10 py-2 rounded-lg mb-2"
                          `}
                        >
                          {labelButtonOK}
                        </button>
                      </div>
                    </div>
                    <div className="absolute top-0 translate-y-[25%] left-1/2 -translate-x-1/2">
                      <img src={IMG_TITLE} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </Fragment>
  );
}
