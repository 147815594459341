import "../../assets/css/HomePage.css";
import "../../assets/css/fontText.css";
import { useEffect, useState } from "react";
import {
  BACK_GLOBAL,
  locationBefore,
  userLocal,
} from "../../services/localService/localService";
import { useLocation, useNavigate } from "react-router-dom";
import { homeServices } from "../../services/apiService/homeService";
import { setAuthorization } from "../../services/apiService/configURL";
import { AxiosRequestConfig, AxiosResponseHeaders } from "axios";
import { HomePageModel } from "../../models/HomePage";
import { useQuery } from "react-query";
import INTRO_BOTTOM from "../../assets/image/Home/intro-bottom.png";
import INTRO_TOP from "../../assets/image/Home/intro-top.png";
import MainNavBar from "../../components/NavBar/MainNavBar";
import GIFT_HOME from "../../assets/image/Icon/gift-home.png";
import Popup18yol from "../../components/Popup/Popup18yol";
import ContactPhoneZalo from "../../components/ContactPhoneZalo/ContactPhoneZalo";
import { ListReceiptServices } from "../../services/apiService/ListReceiveService";

export interface AxiosResponse<T = any, D = any> {
  data: HomePageModel;
  status: number;
  statusText: string;
  headers: AxiosResponseHeaders;
  config: AxiosRequestConfig<D>;
  request?: any;
}

export default function HomePage() {
  const navigation = useNavigate();
  const { pathname } = useLocation();
  let { token, name } = userLocal.get();
  const [listNews, setListNews] = useState<any>();
  const [isOpen18, setIsOpen] = useState(false);
  const nameLogin = localStorage.getItem("NAME");

  localStorage.setItem(BACK_GLOBAL, "");
  document.body.style.backgroundColor = "white";
  setAuthorization(token);
  useEffect(() => {
    localStorage.setItem("LOCATION_STATUS_GAME", "TRUE");
    localStorage.removeItem("REVIEW_IMG");
    localStorage.removeItem("GAME_DATA_LOCAL");
    localStorage.removeItem("LUOT_QUAY");
    localStorage.removeItem("SALE_ID");
    locationBefore.set(pathname);
    homeServices
      .GetNews()
      .then((res: any) => {
        setListNews(res);
      })
      .catch((err) => {
        console.log(err);
      });
    homeServices
      .homeApi()
      .then((res: any) => {
        localStorage.setItem("HOME_DATA_LOCAL", JSON.stringify(res));
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  const { data } = useQuery<any>({
    queryKey: ["getCampsaign"],
    queryFn: () => homeServices.homeApi(),
  });
  const handleTakeAPhoto = () => {
    // navigation("/guide-takeaphoto");
    setIsOpen(true);
  };
  const handleInfor = () => {
    navigation("/infor-customer");
  };
  const handlePrize = () => {
    navigation("/prize");
  };
  const getData = (page: number, reward_type: string) => {
    const data = {
      page: page,
      limit: 10,
      reward_type: reward_type,
    };
    ListReceiptServices.GetListMyGift(data)
      .then((res: any) => {
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {});
  };
  const handleOff = () => {
    getData(1, "all");
    localStorage.setItem("linkRedirect", `/guide-takeaphoto`);
    navigation(`/guide-takeaphoto`);
  };
  const handleOn = () => {
    setIsOpen(false);
  };
  return (
    <div className="">
      <div className="relative top-0 w-full">
        <img src={INTRO_TOP} />
        <div
          className="absolute top-2 right-3 w-6/12 h-12"
          onClick={handleInfor}
        >
          <div className="w-full h-full bg-[#ffffff] rounded-full opacity-40"></div>
          <div className="absolute w-full top-0 px-1 py-1 flex justify-between">
            <div className="pl-2">
              <div className="font-light-mon text-[14px] text-[#363534]">
                Hi
              </div>
              <div className="font-semibold-mon text-[14px]">
                {token && nameLogin}
              </div>
            </div>
            <div className="w-10 h-10 bg-[#f0f0f0] rounded-full flex justify-center items-center">
              <img src={GIFT_HOME} />
            </div>
          </div>
        </div>
      </div>
      <div className="relative -top-[1px] w-full">
        <img src={INTRO_BOTTOM} />
        <button
          onClick={handleTakeAPhoto}
          className="absolute font-bebas-mon text-[22px] shadow-item top-2 uppercase rounded-xl font-bold left-1/2 w-7/12 py-3 font -translate-x-1/2"
          style={{
            backgroundColor: "#CC0403",
            border: "2px solid #FCBA3A",
            color: "#FCBA3A",
          }}
        >
          tham gia ngay
        </button>
        <button
          className="absolute top-24 left-1/2 font-semibold-mon -translate-x-1/2"
          style={{
            color: "#333333",
          }}
          onClick={handlePrize}
        >
          Thể lệ chương trình
        </button>
      </div>
      <MainNavBar />
      <Popup18yol isShow={isOpen18} handleOff={handleOff} handleOn={handleOn} />
      <ContactPhoneZalo />
    </div>
  );
}
