import BG_GIFTTOP from "../../assets/image/Game/bg-success-gifttop.png";
import BG_GIFTBOTTOM from "../../assets/image/Game/bg-success-giftbottom.png";

export default function Item({ res }: { res: any }) {
  console.log(res);
  return (
    <div className="mb-5 ">
      <div className="relative top-0 text-center ">
        <img src={BG_GIFTTOP} loading="lazy" className="w-full" />
        <div className="absolute top-1/2 -translate-y-1/2 w-full flex flex-col items-center justify-center">
          <img
            src={res?.gift_square_image_url}
            loading="lazy"
            className="w-36 ani-title"
          />
          <div className="mt-2 text-[20px] px-8 text-[#CC0403] font-bold-mon">
            {parseInt(res?.quantity) > 1 && `X${res?.quantity}`} {res.gift_name}
          </div>
          <div
            className="text-[13px] font-regular-mon px-5 mt-3 ani-title"
            dangerouslySetInnerHTML={{
              __html: res?.gift_extra_content,
            }}
          ></div>
        </div>
      </div>
      <div className="relative top-0 text-center">
        <img src={BG_GIFTBOTTOM} loading="lazy" className="w-full" />
        <div
          className="absolute top-1/2 -translate-y-1/2 font-regular-mon text-[13px] px-2 ani-guide"
          dangerouslySetInnerHTML={{
            __html: res?.conditions_receiving_prize,
          }}
        ></div>
      </div>
    </div>
  );
}
